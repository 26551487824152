import { FC, memo, useState } from "react"
import { useQuery } from "react-query"
import { ProductCatalogType } from "../../../../contracts/contracts"
import { fetchHits } from "../../../api/catalogAPI"
import { Container } from "../../../styles/utils/StyledGrid"
import { SectionProducts, SectionTitle } from "../../../styles/utils/Utils"
import { VIEW_PRODUCTS_GRID } from "../../../utils/constants"
import { ProductsGrid } from "../../Products/Grid"
import { StyledTileProducts } from "../../Products/StyledProductsGrid"

export type HitsPropsType = { initial?: ProductCatalogType[] }

export const Hits: FC<HitsPropsType> = memo(({ initial }) => {
  const [products, setProducts] = useState([...(initial || [])])

  useQuery(["hits"], () => fetchHits(), {
    staleTime: Infinity,
    initialData: initial,
    onSuccess: (data) => {
      if (data !== null) {
        setProducts([...(data || [])])
      }
    },
  })

  if (!(products.length > 0)) {
    return <></>
  }

  return (
    <SectionProducts>
      <Container>
        <SectionTitle>Хиты</SectionTitle>
        <ProductsGrid
          as={StyledTileProducts}
          products={products}
          view={VIEW_PRODUCTS_GRID}
        />
      </Container>
    </SectionProducts>
  )
})

Hits.displayName = "Hits"
